
export const urls = {
  remoteApiUrl: "https://provectus-consignment-api-cydba3jtma-nw.a.run.app/api"
  //remoteApiUrl: "https://provectus-consignment-api-sx764srmjq-nw.a.run.app/api"
};

//dev url:  "https://provectus-consignment-api-sx764srmjq-nw.a.run.app/api"
//prod url: "https://provectus-consignment-api-cydba3jtma-nw.a.run.app/api"


