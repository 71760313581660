import * as types from "./actionTypes";

import { IConsignmentCore } from "../../interfaces/consignment";

import { downloadConsignmentsByTime } from "../../api/consignmentApiRemote";

export function getConsignmentsByTime(
  isByCreated: boolean,
  start: number,
  end: number,
  projectId: string,
  accessToken: string
) {
  return async function (dispatch: any) {
    try {
      const consignmentsByTime: IConsignmentCore[] =
        await downloadConsignmentsByTime(
          isByCreated,
          start,
          end,
          projectId,
          accessToken
        );
      dispatch(getConsignmentsByTimeComplete(consignmentsByTime, isByCreated));
    } catch (error) {
      dispatch(getConsignmentsByTimeComplete([], isByCreated));
    }
  };
}
function getConsignmentsByTimeComplete(
  consignmentsByTime: IConsignmentCore[],
  isCreation: boolean
): {
  type: string;
  consignmentsByTime: IConsignmentCore[];
  isCreation: boolean;
} {
  return {
    type: types.GET_BULK_CONSIGNMENTS_COMPLETE,
    consignmentsByTime,
    isCreation,
  };
}
