import { urls } from "../environment";
import { IProject, IProjectDetail, ICreateUpdateProject } from "../interfaces/project";
import { makeAuthorisationRecord } from "../common/authFunctions";
import { handleResponse } from "./apiUtils";
import { toast } from "react-toastify";

const projectsUrl = urls.remoteApiUrl + "/projects";

export async function getAllProjectsFromServer(accessToken: string): Promise<IProject[]> {
  // example url: "http://localhost:8080/api/consignment/image/initial/11"
  //example response.url following redirect after following redirect:
  //http://localhost:9000/api/storage/myBucket/images/consignment/completed/11

  const response = await fetch(getProjectsUrl(), {
    headers: makeAuthorisationRecord(accessToken),
  });
  const returnVal: IProject[] = response.ok
    ? await handleResponse(response)
    : [];
  return returnVal;
}

export async function getProjectDetailFromServer(projectId: string, accessToken: string): Promise<IProjectDetail> {
  // example url: "http://localhost:8080/api/consignment/image/initial/11"
  //example response.url following redirect after following redirect:
  //http://localhost:9000/api/storage/myBucket/images/consignment/completed/11

  const response = await fetch(getProjectsUrl()+ "/" + projectId, {
    headers: makeAuthorisationRecord(accessToken),
  });
  const returnVal: IProjectDetail = response.ok
    ? await handleResponse(response)
    : [];
  return returnVal;
}

export async function createProjectOnServer(
  project: ICreateUpdateProject,
  accessToken: string): Promise<IProjectDetail> {
  const response = await fetch(getProjectsUrl(), {
    method: "POST",
    headers: { ...{"accept": "*/*", "Content-Type":"application/json"}, ...makeAuthorisationRecord(accessToken) },
    body: JSON.stringify(project),
  });
  const responseOk = response.ok;
  if (responseOk) {
    toast.success("Project created successfully");
  }
  else {
    toast.error("Project creation failed");
  }
  const returnVal: IProjectDetail = responseOk
  ? await handleResponse(response)
  : [];
return returnVal;
}

export async function updateProjectOnServer(
  project: ICreateUpdateProject,
  oldProjectName: string,
  accessToken: string): Promise<IProjectDetail> {
  const response = await fetch(getProjectsUrl()+"/" + project.id, {
    method: "PUT",
    headers: { ...{"accept": "*/*", "Content-Type":"application/json"}, ...makeAuthorisationRecord(accessToken) },
    body: JSON.stringify(project),
  });
  const responseOk = response.ok;
  if (responseOk) {
    toast.success("Project "+ oldProjectName + " updated successfully");
  }
  else {
    toast.error("Project update failed");
  }
  const returnVal: IProjectDetail = responseOk
  ? await handleResponse(response)
  : [];
return returnVal;
}

function getProjectsUrl() {
  return projectsUrl;
}
