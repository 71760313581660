
import { unknownId } from "../constants/ApiConstants";

export interface IDisposalType {
  id: string;
  ewc: number;
  name: string;
  hazardous: boolean;
}

export const defaultDisposalType: IDisposalType = {
  id: unknownId,
  ewc: 0,
  name: "No Disposal Types Available",
  hazardous: false
};
