import * as authActions from "./redux/actions/authActions";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { ITokenPair } from "./interfaces/auth";
import { bindActionCreators } from "redux";
import "bootstrap/dist/css/bootstrap.min.css";
function Logout(props: {
  
  authActions: {
    logout: (history:any) => void;
  };
  tokenPair: ITokenPair;
}) {
  const history = useHistory();

  function logout() {
    props.authActions.logout(history);
  }
  return (
    <>
      <button className="btn btn-primary" onClick={logout}>
        Logout
      </button>
    </>
  );
}
const mapStateToProps = (state: { tokenPair: ITokenPair }) => {
  return {};
};
const mapDispatchToProps = (dispatch: (arg0: { type: string }) => any) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
};
Logout.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
