import logo from "./images/provectusLogo.png"; // Tell webpack this JS file uses this image
import { ITokenPair } from "./interfaces/auth";
import { connect } from "react-redux";
import { getEmptyToken } from "./common/authFunctions";
import { NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "./index.css";
import { IProject, defaultProject } from "./interfaces/project";

function Header(props: { tokenPair: ITokenPair; selectedProject: IProject }) {
  function isLoggedOut() {
    return props?.tokenPair?.accessDetails?.accessToken === getEmptyToken();
  }
  function isProjectSelected(): boolean {
    return props.selectedProject.id !== defaultProject.id;
  }

  function isLoggedIn() {
    return !isLoggedOut();
  }
  function isAdmin() {
    return isLoggedIn() && props.tokenPair.accessDetails.admin;
  }

  function optionalLoggedInWithProject(name: string, path: string) {
    if (isLoggedIn() && isProjectSelected()) {
      return menuLink(name, path);
    } else {
      return <></>;
    }
  }
  function optionalLoggedInWithoutProject(name: string, path: string) {
    if (isLoggedIn() && !isProjectSelected()) {
      return menuLink(name, path);
    } else {
      return <></>;
    }
  }
  function optionalIsAdmin(name: string, path: string) {
    if (isAdmin()) {
      return menuLink(name, path);
    } else {
      return <></>;
    }
  }

  function optionalLoginLink() {
    if (isLoggedOut()) {
      return menuLink("Login", "/login");
    } else {
      return <></>;
    }
  }
  function optionalLogoutLink() {
    if (isLoggedIn()) {
      return menuLink("Logout", "/logout");
    } else {
      return <></>;
    }
  }
  function menuLink(name: string, path: string) {
    return (
      <NavLink
        className="nav-item nav-link "
        to={path}
        activeClassName="active"
      >
        {name}
      </NavLink>
    );
  }

  return (
    <Navbar expand="lg" bg="light">
      <Nav>
        <Navbar.Brand>
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Nav>
          {optionalLoginLink()}
          {optionalLoggedInWithoutProject("Select Project", "/project")}
          
          {optionalLoggedInWithProject("Consignments", "/consignments")}
          {optionalLoggedInWithProject("Download Images", "/download")}
          {optionalIsAdmin(
            "Admin",
            "/admin"
          )}
          {optionalLogoutLink()}
        </Nav>
      </Nav>
    </Navbar>
  );
}
const mapStateToProps = (state: {
  tokenPair: ITokenPair;
  selectedProject: IProject;
}) => {
  return {
    tokenPair: state.tokenPair,
    selectedProject: state.selectedProject,
  };
};
export default connect(mapStateToProps)(Header);
