import { getEmptyToken } from "../../common/authFunctions";
import { defaultProjectDetail } from "../../interfaces/project";

const initialState = {
  selectedProject: defaultProjectDetail,
  allVehicles: [],
  allDestinations: [],
  allDisposalTypes: [],
  createdConsignments: [],
  completedConsignments: [],
  tokenPair: {
    refreshToken: getEmptyToken(),
    accessDetails: { accessToken: getEmptyToken(), admin: false },
  },

  allProjects: [],
};
export default initialState;
