import * as ApiConstants from "../constants/ApiConstants";
import { urls } from "../environment";
import {
  log,
  authRequestToString,
  authResponseToString,
  tokenRequestToString as tokenShowToString,
} from "../common/logFunctions";
import {
  makeAuthorisationRecord,
  getEmptyToken,
} from "../common/authFunctions";
import { contextLabels, debugLevels } from "../constants/LogConstants";
import { CONSIGNMENT_HEADERS } from "../constants/ApiConstants";
import { IAccessDetails } from "../interfaces/auth";

const getUrlPrefix = urls.remoteApiUrl + "/";
const refreshUrl = getUrlPrefix + "authentication/refresh-token/";
const accessUrl = getUrlPrefix + "authentication/access-token/";
const context = contextLabels.API_REMOTE_AUTH;

export async function getRefreshToken(
  user: string,
  sha256: string
): Promise<string> {
  log(
    debugLevels.INFO,
    authRequestToString({
      context,
      functionName: getRefreshToken.name,
      method: ApiConstants.httpActions.POST,
      url: refreshUrl,
    })
  );

  const body = JSON.stringify({ email: user, password: sha256 });

  const response: Response = await fetch(refreshUrl, {
    method: ApiConstants.httpActions.POST,
    headers: CONSIGNMENT_HEADERS,
    body: body,
  });

  if (!response.ok) {
    return getEmptyToken();
  }

  log(
    debugLevels.INFO,
    authResponseToString({
      context,
      functionName: getRefreshToken.name,
      status: response.status,
    })
  );

  const refreshToken: string = await response.text();
  log(
    debugLevels.DEBUG,
    tokenShowToString({
      context,
      functionName: getRefreshToken.name,
      token: refreshToken,
    })
  );
  return refreshToken;
}

export async function getAccessToken(
  refreshToken: string
): Promise<IAccessDetails> {
  const headers = {
    ...makeAuthorisationRecord(refreshToken),
    accept: "application/json",
  };
  log(
    debugLevels.INFO,
    authRequestToString({
      context,
      functionName: getAccessToken.name,
      method: ApiConstants.httpActions.GET,
      url: accessUrl,
    })
  );

  const response: Response = await fetch(accessUrl, {
    method: ApiConstants.httpActions.GET,
    headers: headers,
  });
  log(
    debugLevels.INFO,
    authResponseToString({
      context,
      functionName: getAccessToken.name,
      status: response.status,
    })
  );
  if (!response.ok) {
    return { accessToken: "", admin: false };
  }
  return await response.json();
}
