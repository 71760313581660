import { IDestination } from "../../interfaces/destination";
import * as types from "../actions/actionTypes";
import initialState from "./theInitialState";

export default function destinationsReducer(
  state: IDestination[] = initialState.allDestinations,
  action: {
    type: string;
    allDestinations: IDestination[];
    destination: IDestination;
  }
) {
  switch (action.type) {
    case types.TOKENS_UPDATED:
      return action.allDestinations;
    case types.CREATE_DESTINATION_COMPLETE:
      return [...state.concat(action.destination)];
    case types.UPDATE_DESTINATION_COMPLETE:
      const arr: IDestination[] = state.map((s) => {
        if (s.id === action.destination.id) {
          return action.destination;
        } else {
          return s;
        }
      });
      return arr;
    case types.LOGOUT:
      return [];
    default:
      return state;
  }
}
