import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk"
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync'

export default function configureStore(initialState:any) {
  const composeEnhancers =
    (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store =  createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, createStateSyncMiddleware(reduxImmutableStateInvariant())))
  );
  initStateWithPrevTab(store);
  return store;
}
