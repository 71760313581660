
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "./redux/configureStore";
import initialState from "./redux/reducers/theInitialState";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import AppRouter from "./AppRouter";

const store = configureStore(initialState);
const TITLE = "Provectus Viewer";
function App() {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <ReduxProvider store={store}>
        <ToastContainer autoClose={3000} hideProgressBar />
        <AppRouter></AppRouter>
      </ReduxProvider>
      <Footer />
    </>
  );
}

export default App;
