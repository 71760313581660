import { urls } from "../environment";
import { IDestination , ICreateUpdateDestination} from "../interfaces/destination";
import { makeAuthorisationRecord } from "../common/authFunctions";
import { handleResponse } from "./apiUtils";
import { toast } from "react-toastify";

const destinationsUrl = urls.remoteApiUrl + "/destinations";

export async function getAllDestinationsFromServer(accessToken: string): Promise<IDestination[]> {
  // example url: "http://localhost:8080/api/consignment/image/initial/11"
  //example response.url following redirect after following redirect:
  //http://localhost:9000/api/storage/myBucket/images/consignment/completed/11

  const response = await fetch(getDestinationsUrl(), {
    headers: makeAuthorisationRecord(accessToken),
  });
  const returnVal: IDestination[] = response.ok
    ? await handleResponse(response)
    : [];
  return returnVal;
}

export async function createDestinationOnServer(
  destination: ICreateUpdateDestination,
  accessToken: string): Promise<IDestination> {
  const response = await fetch(getDestinationsUrl(), {
    method: "POST",
    headers: { ...{"accept": "*/*", "Content-Type":"application/json"}, ...makeAuthorisationRecord(accessToken) },
    body: JSON.stringify(destination),
  });
  const responseOk = response.ok;
  if (responseOk) {
    toast.success("Destination created successfully");
  }
  else {
    toast.error("Destination creation failed");
  }
  const returnVal: IDestination = responseOk
  ? await handleResponse(response)
  : [];
return returnVal;
}

export async function updateDestinationtOnServer(
  destination: ICreateUpdateDestination,
  oldDestination: string,
  accessToken: string): Promise<IDestination> {
  const response = await fetch(getDestinationsUrl()+"/" + destination.id, {
    method: "PUT",
    headers: { ...{"accept": "*/*", "Content-Type":"application/json"}, ...makeAuthorisationRecord(accessToken) },
    body: JSON.stringify(destination),
  });
  const responseOk = response.ok;
  if (responseOk) {
    toast.success("Destination " + oldDestination + " updated successfully");
  }
  else {
    toast.error("Destination  " + oldDestination + " update failed");
  }
  const returnVal: IDestination = responseOk
  ? await handleResponse(response)
  : [];
return returnVal;
}


function getDestinationsUrl() {
  return destinationsUrl;
}
