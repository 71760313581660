import { DATE_NOT_APPLICABLE } from "../constants/DateConstants";
import moment from "moment";

export function presentDateInfo(creationDate: number): string {
  const date: Date = new Date(creationDate);
  if (date.valueOf() === 0) {
    return DATE_NOT_APPLICABLE;
  }
  if (creationDate === 0) {
    return DATE_NOT_APPLICABLE;
  } else {
    return moment(creationDate).format("ddd, DD MMM YYYY HH:mm");
  }
}

export function getNowLogFormat(): string {
  return new Date().toISOString();
}
