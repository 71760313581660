import { useState } from "react";
import CustomTextInput from "./CustomTextInput";
import * as authActions from "./redux/actions/authActions";

import { connect } from "react-redux";
import { ITokenPair } from "./interfaces/auth";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router";
function Login(props: {
  authActions: {
    login: (name: string, password: string, history: any) => void;
  };
  tokenPair: ITokenPair;
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  function onSaveUsername(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setUsername(value);
  }
  function onSavePassword(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setPassword(value);
  }
  function onSubmit(event: { preventDefault: () => void }) {
    event.preventDefault();
    props.authActions.login(username, password, history);

  }
  return (
  
    <form onSubmit={onSubmit} style={{ width: "300px" }}>
      <CustomTextInput
        name="username"
        label="User Name"
        id="username"
        onChange={onSaveUsername}
        minLength={1}
        maxLength={35}
        allowedCharsRegex={""}
        autoCapitalize={"none"}
        inputType="text"
        error={[]}
      />
      <CustomTextInput
        name="password"
        label="Password"
        id="password"
        onChange={onSavePassword}
        minLength={1}
        maxLength={30}
        allowedCharsRegex={""}
        autoCapitalize={"none"}
        inputType="password"
        error={[]}
      />

      <button
        type="submit"
        className="btn btn-primary"
        disabled={username.length === 0 || password.length === 0}
      >
        {"Submit"}
      </button>
    </form>
  );
}
const mapStateToProps = (state: { tokenPair: ITokenPair }) => {
  return {
    tokenPair: state.tokenPair,
  };
};
const mapDispatchToProps = (dispatch: (arg0: { type: string }) => any) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
};
Login.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
