import { IDisposalType } from "./disposal";

export interface IDestination {
  id: string;
  name: string;
  address: string;
  operator: string;
  disposalTypes: IDisposalType[];
}
export interface ICreateUpdateDestination{
  id: string;
  operator: string;
  name: string;
  address: string;
  disposalTypes: string[];
}
export const defaultDestination: IDestination = {
  id: "",
  name: "Invalid Destination",
  operator: "",
  address: "",
  disposalTypes: [],
};

