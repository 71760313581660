import * as authActions from "./redux/actions/authActions";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { connect } from "react-redux";
import { ITokenPair } from "./interfaces/auth";
import { IProject } from "./interfaces/project";
import { bindActionCreators } from "redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { IConsignmentCore } from "./interfaces/consignment";
import { getImages } from "./api/imageApiRemote";
import {
  downloadConsignmentsByTime} from "./api/consignmentApiRemote";
function DownloadImages(props: {
  tokenPair: ITokenPair;
  selectedProject: IProject;
}) {
  function getMidnight() {
    const date: Date = new Date();
    date.setHours(24, 0, 0, 0);
    return date;
  }
  const INITIAL = "initial";
  const COMPLETED = "completed";
  const midnight = getMidnight();
  const [selectionRange, setSelectionRange] = useState({
    startDate: midnight,
    endDate: midnight,
    key: "selection",
  });
  const [consignments, setConsignments] = useState<IConsignmentCore[]>([]);
  const options = [INITIAL, COMPLETED];
  const defaultOption = options[0];

  const [option, setOption] = useState(defaultOption);

  async function onSelectImageType(chosenOption: any) {
    setOption(chosenOption.value);
    updateConsignments(chosenOption.value, selectionRange);
  }

  async function handleSelectDateRange(range: any) {
    await setSelectionRange(range.selection);
    await updateConsignments(option, range.selection);
  }

  async function updateConsignments(chosenOption: any, selectionRange: any) {
    setConsignments(
      await downloadConsignmentsByTime(
        chosenOption === INITIAL,
        selectionRange.startDate.valueOf(),
        selectionRange.endDate.valueOf(),
        props.selectedProject.id,
        props?.tokenPair?.accessDetails?.accessToken
      )
    );
  }
  function doDownload() {

    getImages(
      props.selectedProject.id,
      consignments,
      option,
      props?.tokenPair?.accessDetails?.accessToken
    );
  }

  function getDateRange(range: { startDate: Date; endDate: Date }): string {
    // request a weekday along with a long date
    function formatDate(date: Date) {
      return date.toLocaleString("en-GB", {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    }
    return formatDate(range.startDate) + " & \n" + formatDate(range.endDate);
  }
  return (
    <>
      <div><b>{props.selectedProject.name }</b></div>
      <div style={{ width: "250px", marginBottom: "30px" }}>
        <Dropdown
          options={options}
          onChange={onSelectImageType}
          value={defaultOption}
          placeholder="Select an option"
        />
      </div>
      <div>
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleSelectDateRange}
        />
      </div>
      <div>
        Tip: before downloading multiple images, ensure the option 'ask where to
        save each download' is deselected in your browser settings{" "}
      </div>
      <button
        style={{ marginTop: "10px" }}
        className="btn btn-primary"
        onClick={(e) =>
          consignments.length === 0
            ? alert(
                "There are no " +
                  option +
                  " images to download between dates\n" +
                  getDateRange(selectionRange)
              )
            : window.confirm(
                "Are you sure you wish to download " +
                  consignments.length +
                  " " +
                  option +
                  " images between dates\n" +
                  getDateRange(selectionRange) +
                  "?"
              ) && doDownload()
        }
      >
        Download
      </button>
    </>
  );
}
const mapStateToProps = (state: {
  tokenPair: ITokenPair;
  selectedProject: IProject;
}) => {
  return {
    tokenPair: state.tokenPair,
    selectedProject: state.selectedProject,
  };
};
const mapDispatchToProps = (dispatch: (arg0: { type: string }) => any) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
};
DownloadImages.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadImages);
