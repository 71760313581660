import {
  EMPTY_TOKEN,
  EXPIRY_MARKER,
  BEARER_HEADER,
  TOKEN_SEPARATOR,
} from "../constants/AuthConstants";
//import * as Crypto from "expo-crypto";
import { Base64 } from "js-base64";

export function getEmptyToken(): string {
  return EMPTY_TOKEN;
}

export async function get256(user: string, password: string) {

  const jssha = require('js-sha256')
  const sha256 = jssha.sha256(user + password)
  return sha256;
}

export async function needsRenewal(
  tokenName: string,
  token: string,
  margin: number
) {
  try {
    const decoded = Base64.decode(token.split(TOKEN_SEPARATOR)[1]);
    const json = await JSON.parse(decoded);
    const expiryTime = parseInt(json[EXPIRY_MARKER]) * 1000;
    const nowTime = Date.now();
    return nowTime + margin > expiryTime;
  } catch (error) {
    return true;
  }
}

export function makeAuthorisationRecord(
  bearer: string
): Record<string, string> {
  return { Authorization: BEARER_HEADER + bearer };
}
