import {
  DEBUG_LEVEL,
  LOG_SEPARATOR,
  INITIAL_IMAGE_NAME_IN_LOGS,
  COMPLETED_IMAGE_NAME_IN_LOGS,
} from "../constants/LogConstants";

import {

  IAuthRequestLog,
  IAuthResponseLog,
  ITokenLog,
  IConsignmentsByTimeRequestLog,
} from "../interfaces/log";
import { getNowLogFormat } from "../common/dateFunctions";

export function log(level: number, message: string): void {
  if (level <= DEBUG_LEVEL) {
  }
}

export function consignmentsByTimeRequestToString(request: IConsignmentsByTimeRequestLog) {
  return (
    getCommon(request) +
    request.isByCreated +
    LOG_SEPARATOR + 
    request.method +
    LOG_SEPARATOR +
    request.url
  );
}

export function authRequestToString(request: IAuthRequestLog) {
  return getCommon(request) + request.method + LOG_SEPARATOR + request.url;
}
export function authResponseToString(request: IAuthResponseLog) {
  return getCommon(request) + request.status;
}
export function tokenRequestToString(request: ITokenLog) {
  return getCommon(request) + LOG_SEPARATOR + request.token;
}

export function getCommon(request: any) {
  return (
    getNowLogFormat() +
    request.context +
    LOG_SEPARATOR +
    request.functionName +
    LOG_SEPARATOR
  );
}

export function formatImage(isInitial: boolean): string {
  return isInitial ? INITIAL_IMAGE_NAME_IN_LOGS : COMPLETED_IMAGE_NAME_IN_LOGS;
}
