import * as types from "./actionTypes";
import { get256, getEmptyToken } from "../../common/authFunctions";
import { EMPTY_TOKEN } from "../../constants/AuthConstants";
import { toast } from "react-toastify";
import { IAccessDetails, ITokenPair } from "../../interfaces/auth";
import { IProject } from "../../interfaces/project";
import * as authApi from "../../api/authApiRemote";
import * as projectApi from "../../api/projectApiRemote";
import * as vehicleApi from "../../api/vehicleApiRemote";
import * as destinationApi from "../../api/destinationApiRemote";
import * as disposalTypeApi from "../../api/disposalTypeApiRemote";
import { IVehicleType } from "../../interfaces/vehicle";
import { IDestination } from "../../interfaces/destination";
import { IDisposalType } from "../../interfaces/disposal";

const emptyToken = getEmptyToken();
const emptyTokens = {
  refreshToken: emptyToken,
  accessDetails: { accessToken: emptyToken, admin: false },
};

function doAuthorisationDispatch(
  dispatch: any,
  tokenPair: ITokenPair,
  allProjects: IProject[],
  allVehicles: IVehicleType[],
  allDestinations: IDestination[],
  allDisposalTypes: IDisposalType[]
) {
  dispatch(
    authorisationCheckComplete(
      tokenPair,
      allProjects,
      allVehicles,
      allDestinations,
      allDisposalTypes
    )
  );
}

export function login(user: string, password: string, history: Array<String>) {
  return async function (dispatch: any) {
    try {
      const sha256: string = await get256(user, password);
      const refreshToken: string = await authApi.getRefreshToken(user, sha256);

      if (refreshToken === EMPTY_TOKEN) {
        throw new Error();
      }
      const accessDetails: IAccessDetails = await authApi.getAccessToken(
        refreshToken
      );
      const tokenPair = { refreshToken, accessDetails };

      if (accessDetails.accessToken === EMPTY_TOKEN) {
        throw new Error();
      } else if (!accessDetails.admin) {
        toast.success("Login succeeded");
        const allProjects: IProject[] = await projectApi.getAllProjectsFromServer(
          accessDetails.accessToken
        );
        const allVehicles: IVehicleType[] = await vehicleApi.getAllVehicles(
          accessDetails.accessToken
        );
        doAuthorisationDispatch(
          dispatch,
          tokenPair,
          allProjects,
          allVehicles,
          [],
          []
        );
      }
      else {
        const allProjects: IProject[] =
          await projectApi.getAllProjectsFromServer(accessDetails.accessToken);
        const allVehicles: IVehicleType[] = await vehicleApi.getAllVehicles(
          accessDetails.accessToken
        );
        const allDestinations: IDestination[] =
          await destinationApi.getAllDestinationsFromServer(
            accessDetails.accessToken
          );
        const allDisposalTypes: IDisposalType[] =
          await disposalTypeApi.getDisposalTypesFromServer(
            accessDetails.accessToken
          );

        toast.success("Admin login succeeded");
        doAuthorisationDispatch(
          dispatch,
          tokenPair,
          allProjects,
          allVehicles,
          allDestinations,
          allDisposalTypes
        );
      }
    } catch (error) {
      toast.error("Login failed");
      doAuthorisationDispatch(dispatch, emptyTokens, [], [], [], []);
    }
  };
}

export function logout(history: Array<String>) {
  toast.success("Logged out");
  return { type: types.LOGOUT };
}

function authorisationCheckComplete(
  tokenPair: ITokenPair,
  allProjects: IProject[],
  allVehicles: IVehicleType[],
  allDestinations: IDestination[],
  allDisposalTypes: IDisposalType[]
): {
  type: string;
  tokenPair: ITokenPair;
  allProjects: IProject[];
  allVehicles: IVehicleType[];
  allDisposalTypes: IDisposalType[];
  allDestinations: IDestination[];
} {
  return {
    type: types.TOKENS_UPDATED,
    tokenPair,
    allProjects,
    allVehicles,
    allDestinations,
    allDisposalTypes,
  };
}
