import { Navbar } from 'react-bootstrap';

function Footer() {
    return (
        <Navbar bg="light" fixed="bottom">
             <span className="small text-muted">Automation software by <a href="mailto:mail@huw.org.uk?body=This email enquiry has been initiated via the Provectus Consignment Analysis Tool"> Huw Ayling-Miller {"&"} Andy Boothroyd</a>
            {" | "} Mobile Data Capture {" -> "} Secure Cloud Storage {" -> "} Data Analytics</span>
        </Navbar>
    );
  }

export default Footer;
