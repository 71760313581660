import { hazardClassifications } from "../constants/PresentationConstants";
import { IDisposalType } from "../interfaces/disposal";
export function getHazardText(consignmentCode: number) {
  if (consignmentCode < 30000) {
    return hazardClassifications.NON_HAZARDOUS;
  } else if (consignmentCode < 50000) {
    return hazardClassifications.RECYCLABLE;
  } else {
    return hazardClassifications.HAZARDOUS;
  }
}

export function augmentDisposalType(disposalType: IDisposalType) {
  return { ...disposalType, displayValue : disposalType.ewc + " : " + disposalType.name };
}
export function getEwcAsString(ewc: number) {
  return ewc.toString().padStart(6, '0');
}
