import * as types from "../actions/actionTypes";
import initialState from "./theInitialState";
import { IConsignmentCore } from "../../interfaces/consignment";

export default function completedConsignmentsReducer(
  state: IConsignmentCore[] = initialState.completedConsignments,
  action: {
    type: string;
    consignmentsByTime: IConsignmentCore[];
    isCreation: boolean;
  }
) {
  switch (action.type) {

    case types.GET_BULK_CONSIGNMENTS_COMPLETE:
      
      return action.isCreation ? state : action.consignmentsByTime;
    
    case types.LOGOUT:
      return [];
    default:
      return state;
  }
}
