import { urls } from "../environment";
import { IDisposalType } from "../interfaces/disposal";
import { makeAuthorisationRecord } from "../common/authFunctions";
import { handleResponse } from "./apiUtils";
import { toast } from "react-toastify";
import { getEwcAsString } from "../common/presentationFunctions";

const disposalTypeUrl = urls.remoteApiUrl + "/disposal-types";

export async function getDisposalTypesFromServer(accessToken: string): Promise<IDisposalType[]> {
  // example url: "http://localhost:8080/api/consignment/image/initial/11"
  //example response.url following redirect after following redirect:
  //http://localhost:9000/api/storage/myBucket/images/consignment/completed/11

  const response = await fetch(getDisposalTypesUrl(), {
    headers: makeAuthorisationRecord(accessToken),
  });
  const returnVal: IDisposalType[] = response.ok
    ? await handleResponse(response)
    : [];
  return returnVal;
}

function getDisposalTypesUrl() {
  return disposalTypeUrl;
}
export async function createDisposalTypeOnServer(
  disposalType: IDisposalType,
  accessToken: string): Promise<IDisposalType> {
  const response = await fetch(getDisposalTypesUrl(), {
    method: "POST",
    headers: { ...{"accept": "*/*", "Content-Type":"application/json"}, ...makeAuthorisationRecord(accessToken) },
    body: JSON.stringify(disposalType),
  });
  const responseOk = response.ok;
  if (responseOk) {
    toast.success("Disposal Type created successfully");
  }
  else {
    toast.error("Disposal Type creation failed");
  }
  const returnVal: IDisposalType = responseOk
  ? await handleResponse(response)
  : [];
return returnVal;
}

export async function updateDisposalTypeOnServer(
  disposalType: IDisposalType,
  accessToken: string): Promise<IDisposalType> {
  const response = await fetch(getDisposalTypesUrl()+"/" +disposalType.id, {
    method: "PUT",
    headers: { ...{"accept": "*/*", "Content-Type":"application/json"}, ...makeAuthorisationRecord(accessToken) },
    body: JSON.stringify(disposalType),
  });
  const responseOk = response.ok;
  const disposalTypeAsString = getEwcAsString(disposalType.ewc);
  if (responseOk) {
    toast.success("Disposal Type " + disposalTypeAsString + " updated successfully");
  }
  else {
    toast.error("Disposal Type " + disposalTypeAsString + "  update failed");
  }
  const returnVal: IDisposalType = responseOk
  ? await handleResponse(response)
  : [];
return returnVal;
}

