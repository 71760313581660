import { unknownId } from "../constants/ApiConstants";
export interface IVehicleType {
  id: string;
  registrationMark: string;
  haulageCompanyId: string;
  capacity: number;
}

export const defaultVehicle: IVehicleType = {
  id: unknownId,
  registrationMark: "No Vehicles",
  haulageCompanyId: unknownId,
  capacity: 0,
};
