import { combineReducers } from "redux";

import tokenPair from "./tokenReducer";
import createdConsignments from "./createdConsignmentsReducer";
import completedConsignments from "./completedConsignmentsReducer";
import allDestinations from "./allDestinationsReducer";
import allProjects from "./allProjectsReducer";
import allVehicles from "./allVehicleReducer";
import selectedProject from "./selectedProjectReducer";
import allDisposalTypes from "./allDisposalTypesReducer";

import { withReduxStateSync } from "redux-state-sync";

const rootReducer = combineReducers({
  tokenPair,
  createdConsignments,
  completedConsignments,
  allProjects,
  allDestinations,
  allDisposalTypes,
  allVehicles,
  selectedProject
});

export default withReduxStateSync(rootReducer);
