import { connect } from "react-redux";
import { ITokenPair } from "./interfaces/auth";
import { defaultProject, IProject } from "./interfaces/project";

import Login from "./Login";
import Logout from "./Logout";
import DownloadImages from "./DownloadImages";
import Consignments from "./Consignments";
import AdminLinks from "./AdminLinks";
import CreateProject from "./components/CreateProject";
import UpdateProject from "./components/UpdateProject";
import CreateDestination from "./components/CreateDestination";
import UpdateDestination from "./components/UpdateDestination";
import CreateDisposalType from "./components/CreateDisposalType";
import UpdateDisposalType from "./components/UpdateDisposalType";
import Header from "./Header";
import { getEmptyToken } from "./common/authFunctions";

import {
  BrowserRouter as Router,
  Redirect,
  Route /*Link*/,
} from "react-router-dom";
import { Container } from "react-bootstrap";
import ProjectSelect from "./ProjectSelect";

function AppRouter(props: {
  tokenPair: ITokenPair;
  selectedProject: IProject;
}) {
  function isProjectSelected() {
    return props.selectedProject && props.selectedProject.id !== defaultProject.id;
  }
  function isLoggedOut() {
    return props?.tokenPair?.accessDetails?.accessToken === getEmptyToken();
  }

  
  return (
    <Router>
      <Header />
      <Container>
        <Route path="/">
          <Redirect
            to={
              isLoggedOut()
                ? "/login"
                : isProjectSelected()
                ? "/consignments"
                : "/project"
            }
          ></Redirect>
        </Route>
        <Route path="/login" exact component={Login} />
        <Route path="/consignments" component={Consignments} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/download" exact component={DownloadImages} />
        <Route path="/admin" exact component={AdminLinks} />
        <Route path="/createdisposaltype" exact component={CreateDisposalType} />
        <Route path="/updatedisposaltype" exact component={UpdateDisposalType} />
        <Route path="/createproject" exact component={CreateProject} />
        <Route path="/updateproject" exact component={UpdateProject} />
        <Route path="/createdestination" exact component={CreateDestination} />
        <Route path="/updatedestination" exact component={UpdateDestination} />
        <Route path="/project" exact component={ProjectSelect} />
      </Container>
    </Router>
  );
}
const mapStateToProps = (state: {
  tokenPair: ITokenPair;
  selectedProject: IProject;
  allProjects: IProject[];
}) => {
  return {
    tokenPair: state.tokenPair,
    selectedProject: state.selectedProject,
    allProjects: state.allProjects
  };
};

export default connect(mapStateToProps)(AppRouter);
