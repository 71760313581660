// In a real app, would likely call an error logging service.
export function handleError(error: string) {
  // eslint-disable-next-line no-console
  throw new Error(error);
}

export async function handleResponse(response: Response) {
  if (response.ok) return response.json();
  throw new Error("Network response was not ok.");
}
