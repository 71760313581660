import { IDisposalType } from "../../interfaces/disposal";
import * as types from "../actions/actionTypes";
import initialState from "./theInitialState";

export default function destinationsReducer(
  state: IDisposalType[] = initialState.allDisposalTypes,
  action: {
    type: string;
    allDisposalTypes: IDisposalType[];
    disposalType: IDisposalType;
  }
) {
  switch (action.type) {
    case types.TOKENS_UPDATED:
      return action.allDisposalTypes;
    case types.CREATE_DISPOSAL_TYPE_COMPLETE:
      const aggregate = [...state.concat(action.disposalType)];
      return aggregate;
    case types.UPDATE_DISPOSAL_TYPE_COMPLETE:
      const arr: IDisposalType[] = state.map((s) => {
        if (s.id === action.disposalType.id) {
          return action.disposalType;
        } else {
          return s;
        }
      });
      return arr;
    case types.LOGOUT:
      return [];
    default:
      return state;
  }
}
