import * as authActions from "./redux/actions/authActions";
import { connect } from "react-redux";
import { useState } from "react";
import { ITokenPair } from "./interfaces/auth";
import { bindActionCreators } from "redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { defaultProject, IProject, IProjectDetail } from "./interfaces/project";
import * as projectActions from "./redux/actions/projectActions";

function ProjectSelect(props: {
  authActions: {
    logout: (history: any) => void;
  };
  projectActions: {
    retrieveProjectDetail: (projectId: string, accessToken: string) => void;
  };
  tokenPair: ITokenPair;
  allProjects: IProject[];
}) {
  const [project, setProject] = useState<IProject>(
    props.allProjects && props.allProjects.length > 0
      ? props.allProjects[0]
      : defaultProject
  );
  const options = props.allProjects;

  async function onSelectProject(selectedProject: any): Promise<void> {
    setProject(
      props.allProjects.find((p) => p.name === selectedProject.label) ||
        defaultProject
    );
  }

  function onSubmit(event: { preventDefault: () => void }) {
    event.preventDefault();
    props.projectActions.retrieveProjectDetail(
      project.id,
      props?.tokenPair?.accessDetails?.accessToken
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <div style={{ width: "300px", marginBottom: "30px" }}>
        <Dropdown
          options={options.map((o) => o.name)}
          onChange={onSelectProject}
          value={props.allProjects[0]?.name || ""}
          placeholder="Select an option"
        />
      </div>
      <button
        type="submit"
        className="btn btn-primary"
        disabled={project === defaultProject}
      >
        {"Submit Project"}
      </button>
    </form>
  );
}
const mapStateToProps = (state: {
  tokenPair: ITokenPair;
  allProjects: IProject[];
  projectDetail: IProjectDetail;
}) => {
  return {
    tokenPair: state.tokenPair,
    allProjects: state.allProjects,
    projectDetail: state.projectDetail,
  };
};
const mapDispatchToProps = (dispatch: (arg0: { type: string }) => any) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    projectActions: bindActionCreators(projectActions, dispatch),
  };
};
ProjectSelect.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelect);
