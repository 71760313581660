export const httpActions = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
};
export const UPLOAD_EXTENSION: string = "upload/";
export const NETWORK_FAILURE_MESSAGE: string = "Network response was not ok.";
export const IMAGE_UPLOAD_TYPE: string = "photo";
export const UPLOAD_PHOTO_NAME: string = "photo.jpg";
export const DATE_HEADER = "last-modified";

const CONTENT_TYPE_KEY: string = "Content-Type";
const WILDCARD: string = "*/*";
const ACCEPT_KEY: string = "accept";
const APPLICATION_JSON: string = "application/json";

export const CONSIGNMENT_HEADERS: Record<string, string> = {
  [CONTENT_TYPE_KEY]: APPLICATION_JSON,
  [ACCEPT_KEY]: WILDCARD,
};
export const BULK_CONSIGNMENT_HEADERS: Record<string, string> = {
  [ACCEPT_KEY]: APPLICATION_JSON,
};

export const FAKE_API_IMAGE_HEADERS: Record<string, string> = {
  [CONTENT_TYPE_KEY]: APPLICATION_JSON,
};
export const TARGET_API_IMAGE_HEADERS: Record<string, string> = {
  [CONTENT_TYPE_KEY]: "images/jpeg",
  [ACCEPT_KEY]: WILDCARD,
};

export const FAKE_API_CONSIGNMENT_CODE_QUERY_PARAMETER = "?consignmentCode=";

export const apiActions = {
  UPLOAD: "upload",
  DOWNLOAD: "download",
};
export const unknownId: string = "0";