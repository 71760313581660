import * as consignmentActions from "./redux/actions/consignmentActions";
import { connect } from "react-redux";
import { useState } from "react";
import { ITokenPair } from "./interfaces/auth";

import { IProjectDetail } from "./interfaces/project";
import { bindActionCreators } from "redux";
import { IConsignmentCore } from "./interfaces/consignment";
import { AgGridReact } from "ag-grid-react";
import { DATE_NOT_APPLICABLE } from "./constants/DateConstants";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { presentDateInfo } from "./common/dateFunctions";
import "bootstrap/dist/css/bootstrap.min.css";
import { getImages } from "./api/imageApiRemote";
import "./index.css";
import { GridApi, ColumnApi } from "@ag-grid-community/core";
import { defaultVehicle, IVehicleType } from "./interfaces/vehicle";
import {  IDestination, defaultDestination } from "./interfaces/destination";
import { getEwcAsString } from "./common/presentationFunctions";

interface ISpreadsheetConsignment extends IConsignmentCore {
  destinationName: string;
  vehicleRegistration: string;
  presentationWasteDisposalDate: string;
  presentationInitialImageDate: string;
  presentationCompletedImageDate: string;
}
function Consignments(props: {
  consignmentActions: {
    getConsignmentsByTime(
      isByCreated: boolean,
      start: number,
      end: number,
      projectId: string,
      accessToken: string
    ): void;
  };
  allVehicles: IVehicleType[];
  createdConsignments: ISpreadsheetConsignment[];
  selectedProject: IProjectDetail;
  tokenPair: ITokenPair;
}) {
  const [gridApi, setGridApi] = useState(new GridApi());
  const [columnApi, setColumnApi] = useState(new ColumnApi());

  function nonApplicableIfEmptyNumber(value: number): string {
    return value === 0 || value == null ? "N/A" : value.toString();
  }
  function nonApplicableIfEmptyString(value: string): string {
    return  value == null ? "N/A" : value;
  }
  function getEwcCode(disposalTypeId: string): number {
    var ewcCode: number = 0;
    props.selectedProject.destinations.forEach(destination => {
      destination.disposalTypes.forEach(disposalType => {
        if (disposalType.id === disposalTypeId) {
          ewcCode = disposalType.ewc;
        }
      })
    });
    return ewcCode;
  }

  function downloadableText(
    date: string,
    type: string,
    projectId: string,
    consignment: IConsignmentCore
  ) {
    return date === DATE_NOT_APPLICABLE ? (
      date
    ) : (
      <span
        style={{ cursor: "pointer", color: "blue" }}
        onClick={() => {
          getImages(
            projectId,
            [consignment],
            type,
            props?.tokenPair?.accessDetails?.accessToken
          );
        }}
      >
        {date}{" "}
      </span>
    );
  }
  var columnDefs = [
    {
      headerName: "Code",
      width: 70,
      field: "consignmentCode",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Destination",
      width: 180,
      field: "destinationName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Load Type",
      width: 170,
      field: "loadType",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Vehicle Reg",
      width: 100,
      field: "vehicleRegistration",
      sortable: true,
      filter: true,
    },
    {
      headerName: "EWC",
      width: 80,
      field: "disposalType",
      sortable: true,
      filter: true,
      cellRendererFramework: function (row: any) {
        return getEwcAsString(getEwcCode(
          row.data.disposalType),
        );
      },
    },
    {
      headerName: "Count Type",
      width: 105,
      field: "countType",
      sortable: true,
      filter: true,
      cellRendererFramework: function (row: any) {
        return nonApplicableIfEmptyString(
          row.data.countType,
        );
      },
    },
    {
      headerName: "Count",
      width: 75,
      field: "count",
      sortable: true,
      filter: true,
      cellRendererFramework: function (row: any) {
        return nonApplicableIfEmptyNumber(
          row.data.count,
        );
      },
    },
    {
      headerName: "Weight",
      width: 78,
      field: "weight",
      sortable: true,
      filter: true,
      cellRendererFramework: function (row: any) {
        return nonApplicableIfEmptyNumber(
          row.data.weight,
        );
      },
    },
    {
      headerName: "Waste Disposal Date",
      width: 185,
      field: "presentationWasteDisposalDate",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Initial Entry Date",
      width: 185,
      field: "presentationInitialImageDate",
      sortable: true,
      filter: true,
      cellRendererFramework: function (row: any) {
        return downloadableText(
          row.data.presentationInitialImageDate,
          "initial",
          props.selectedProject.id,
          row.data
        );
      },
    },
    {
      headerName: "Completion Date",
      width: 185,
      field: "presentationCompletedImageDate",
      sortable: true,
      filter: true,
      cellRendererFramework: function (row: any) {
        return downloadableText(
          row.data.presentationCompletedImageDate,
          "completed",
          props.selectedProject.id,
          row.data
        );
      },
    },
  ];

  function refresh() {

    props.consignmentActions.getConsignmentsByTime(
      true,
      new Date(0).valueOf(),
      Date.now(),
      props.selectedProject.id,
      props?.tokenPair?.accessDetails?.accessToken
    );
  }
  function onGridReady(params: any) {
    // using hooks - setGridApi/setColumnApi are returned by useState
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  }
  function exportAsCSV() {
    gridApi.exportDataAsCsv({
      columnKeys: columnApi
        .getAllDisplayedColumns()
        .map((column: any) => column.getColId()),
      processCellCallback: function (params: any) {
        return params.value;
      },
    });
  }
  return (
    <>
      <span>
        {"Project: "}
        <span>
          <b>{props.selectedProject.name}</b>{" "}
        </span>
        <button
          onClick={refresh}
          className="btn btn-primary table-action-button"
        >
          {"Update Table"}
        </button>
      </span>

      <button
        onClick={exportAsCSV}
        className="btn btn-primary table-action-button"
      >
        Export as CSV
      </button>

      <div className="ag-theme-balham consignment-table" >

        {/*@ts-ignore*/}
        <AgGridReact
          onGridReady={onGridReady}
          pagination="true"
          paginationAutoPageSize={true}
          rowHeight="25"
          columnDefs={columnDefs}
          rowData={props.createdConsignments}
        ></AgGridReact>
      </div>
    </>
  );
}
function findDestinationName(
  destinationId: string,
  selectedProject: IProjectDetail
): string {
  const foundDestination: IDestination =
    selectedProject.destinations.find((d) => d.id === destinationId) ||
    defaultDestination;
  return foundDestination.name;
}
function findRegistrationMark(
  vehicleId: string,
  allVehicles: IVehicleType[]
): string {
  const vehicle: IVehicleType =
    allVehicles.find((v) => v.id === vehicleId) || defaultVehicle;
  return vehicle.registrationMark;
}
function extendConsignment(
  c: IConsignmentCore,
  selectedProject: IProjectDetail,
  allVehicles: IVehicleType[]
): ISpreadsheetConsignment {
  return {
    ...c,
    destinationName: findDestinationName(c.destinationSite, selectedProject),
    vehicleRegistration: findRegistrationMark(c.vehicleId, allVehicles),
    presentationWasteDisposalDate: presentDateInfo(c.wasteDisposalDate),
    presentationInitialImageDate: presentDateInfo(c.initialImageDate),
    presentationCompletedImageDate: presentDateInfo(c.completedImageDate),
  };
}
const mapStateToProps = (state: {
  tokenPair: ITokenPair;
  selectedProject: IProjectDetail;
  allVehicles: IVehicleType[];
  createdConsignments: IConsignmentCore[];
}) => {
  return {
    tokenPair: state.tokenPair,
    selectedProject: state.selectedProject,
    allVehicles: state.allVehicles,
    createdConsignments: state.createdConsignments.map((c) =>
      extendConsignment(c, state.selectedProject, state.allVehicles)
    ),
  };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string }) => any) => {
  return {
    consignmentActions: bindActionCreators(consignmentActions, dispatch),
  };
};
Consignments.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(Consignments);
