import { IDestination } from "./destination";
import { unknownId } from "../constants/ApiConstants";
export interface IProject {
  id: string;
  name: string;
}

export interface IProjectDetail extends IProject {
  description: string;
  destinations: IDestination[];
}
export interface ISimpleProjectDetail extends IProject {
  description: string;
  destinations: string[];
}
export interface ICreateUpdateProject {
  id: string;
  name: string;
  description: string;
  destinations: string[];
}
export const defaultProject: IProject = {
  id: unknownId,
  name: "No projects available",
};
export const defaultProjectDetail: IProjectDetail = {
  id: unknownId,
  name: "No projects available",
  description: "project download failed",
  destinations: [],
};
