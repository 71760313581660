import * as types from "./actionTypes";

import {
  IDisposalType, defaultDisposalType,
} from "../../interfaces/disposal";
import { getDisposalTypesFromServer, createDisposalTypeOnServer, updateDisposalTypeOnServer} from "../../api/disposalTypeApiRemote";

export function createDisposalType(
  newDisposalType: IDisposalType,
  accessToken: string
) {
  return async function (dispatch: any) {
    try {
      const disposalType: IDisposalType = await createDisposalTypeOnServer(
        newDisposalType,
        accessToken
      );

      dispatch({ type: types.CREATE_DISPOSAL_TYPE_COMPLETE, disposalType});
    } catch (error) {
      dispatch({
        type: types.CREATE_DISPOSAL_TYPE_COMPLETE,
        disposalType: defaultDisposalType,
      });
    }
  };
}
export function updateDisposalType(
  updateDisposalType: IDisposalType,
  accessToken: string
) {
  return async function (dispatch: any) {
    try {
      const disposalType: IDisposalType = await updateDisposalTypeOnServer(
       updateDisposalType,
        accessToken
      );

      dispatch({ type: types.UPDATE_DISPOSAL_TYPE_COMPLETE, disposalType });
    } catch (error) {
      dispatch({
        type: types.UPDATE_DISPOSAL_TYPE_COMPLETE,
        selectedDestination: defaultDisposalType,
      });
    }
  };
}

export function retrieveDisposalTypes( accessToken: string) {
  return async function (dispatch: any) {
    try {
      const disposalTypes: IDisposalType[] = await getDisposalTypesFromServer(
        accessToken
      );
      dispatch(retrieveDisposalTypesComplete(disposalTypes));
    } catch (error) {
      dispatch(retrieveDisposalTypesComplete([]));
    }
  };

  function retrieveDisposalTypesComplete(disposalTypes: IDisposalType[]): {
    type: string;
    disposalTypes: IDisposalType[];
  } {
    return {
      type: types.GET_DISPOSAL_TYPES_COMPLETE,
      disposalTypes,
    };
  }
}