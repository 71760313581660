import { urls } from "../environment";
import { IVehicleType } from "../interfaces/vehicle";
import { handleResponse } from "./apiUtils";

import { makeAuthorisationRecord } from "../common/authFunctions";

const vehicleUrl = urls.remoteApiUrl + "/vehicles/";
export async function getAllVehicles(
  accessToken: string
): Promise<IVehicleType[]> {
  // example url: "http://localhost:8080/api/consignment/image/initial/11"
  //example response.url following redirect after following redirect:
  //http://localhost:9000/api/storage/myBucket/images/consignment/completed/11
  
  const response = await fetch(vehicleUrl, {
    headers: makeAuthorisationRecord(accessToken),
  });
 
  const returnVal: IVehicleType[] = response.ok
    ? await handleResponse(response)
    : [];
  return returnVal;
}

