import { handleResponse } from "./apiUtils";
import { urls } from "../environment";
import { IConsignmentCore } from "../interfaces/consignment";

import * as ApiConstants from "../constants/ApiConstants";
import { contextLabels, debugLevels } from "../constants/LogConstants";
import { makeAuthorisationRecord } from "../common/authFunctions";

import { log, consignmentsByTimeRequestToString } from "../common/logFunctions";
const baseUrl =
  urls.remoteApiUrl + "/projects";
 
function getProjectSpecificConsignmentUrl(projectId: string) {
    return baseUrl + "/" + projectId + "/consignments"
  }

const context = contextLabels.API_REMOTE_CONSIGNMENT;

export async function downloadConsignmentsByTime(
  isByCreated: boolean,
  start: number,
  end: number,
  projectId: string,
  accessToken: string
): Promise<IConsignmentCore[]> {
  const baseUrl = getProjectSpecificConsignmentUrl(projectId);
  const queryString1: string =
    "?newest=" + Date.now() + "&oldest=" + 0 + "&sortBy=";
  const queryString2: string = isByCreated ? "CREATED" : "COMPLETED";
  const absUrl = baseUrl + queryString1 + queryString2;

  log(
    debugLevels.DEBUG,
    consignmentsByTimeRequestToString({
      context,
      functionName: downloadConsignmentsByTime.name,
      isByCreated: true,
      method: ApiConstants.httpActions.GET,
      url: absUrl,
    })
  );
  const response: Response = await fetch(absUrl, {
    headers: {
      ...ApiConstants.BULK_CONSIGNMENT_HEADERS,
      ...makeAuthorisationRecord(accessToken),
    },
  });
  function isInRange(date: number, start: number, end: number) {
    if (date === null) {
      return false;
    }
    const dateAsNumber = new Date(date).valueOf();
    
    return date !== null && dateAsNumber >= start && dateAsNumber <= end;
  }

  const consignments: IConsignmentCore[] = await handleResponse(response);
  const filteredConsignments:IConsignmentCore[] = consignments.filter(
    function (c) {
      return (
        isInRange(c.creationDate, start, end) ||
        isInRange(c.completedImageDate, start, end)
      );
    }
  );

  return filteredConsignments;
}
