import { NavLink } from "react-router-dom";


function menuLink(name: string, path: string) {
  return (
    <NavLink
      className="nav-item nav-link "
      to={path}
      activeClassName="active"
    >
      {name}
    </NavLink>
  );
}

function Footer() {
  return (
    <div>
      {menuLink("Create DisposalType", "/createdisposaltype")}
      {menuLink("Update DisposalType", "/updatedisposaltype")}
      {menuLink("Create Destination", "/createdestination")}
      {menuLink("Update Destination", "/updatedestination")}
      {menuLink("Create Project", "/createproject")}
      {menuLink("Update Project", "/updateproject")}

      

      </div>
  
    );
  }

export default Footer;
