import { urls } from "../environment";

import download from "downloadjs";
import { makeAuthorisationRecord } from "../common/authFunctions";
import { httpActions } from "../constants/ApiConstants";
import { IConsignmentCore } from "../interfaces/consignment";

const baseUrl = urls.remoteApiUrl + "/projects";

function getUrl(
  projectId: string,
  consignmentId: string,
  type: string
) {
  return baseUrl +
    "/" +
    projectId +
    "/consignments/" +
    consignmentId +
    "/image/" +
   type;
}
export async function getImages(
  projectId: string, 
  consignments: IConsignmentCore[],
  type: string,
  accessToken: string
) {
  // example url: "http://localhost:8080/api/consignment/image/initial/11"
  //example response.url following redirect after following redirect:
  //http://localhost:9000/api/storage/myBucket/images/consignment/completed/11

  for (const c of consignments) {
    const url: string = getUrl(projectId, c.consignmentId, type);
    const response = await fetch(url, {
      method: httpActions.GET,
      headers: makeAuthorisationRecord(accessToken),
    });
    if (response.ok) {
      await download(await response.blob(), type + c.consignmentCode + ".jpg", "image/jpeg");
    }
  }
}