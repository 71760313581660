import {
    IVehicleType,
  } from "../../interfaces/vehicle";
  import * as types from "../actions/actionTypes";
  import initialState from "./theInitialState";
  
  export default function vehiclesReducer(
    state: IVehicleType[] = initialState.allVehicles,
    action: {
      type: string;
      allVehicles: IVehicleType[];
    }
  ) {
    switch (action.type) {
      case types.TOKENS_UPDATED:
        return action.allVehicles;


      case types.LOGOUT:
        return [];
      default:
        return state;
    }
  }
  