import React from "react";
import PropTypes from "prop-types";
import { TextProperties } from "./interfaces/TextProperties";

function CustomTextInput(props: TextProperties) {
  let wrapperClass = "form-group";

  function filterChars(e: React.KeyboardEvent<HTMLInputElement>) {
    if (!new RegExp(props.allowedCharsRegex).test(e.key)) {
      e.preventDefault();
    }
  }

  return (
    <div className={wrapperClass}>
      <label htmlFor={props.id}>{props.label}</label>
      <div className="field">
        <input
          autoCapitalize={props.autoCapitalize}
          onKeyPress={(e) => filterChars(e)}
          id={props.id}
          type={props.inputType}
          onChange={props.onChange}
          className="form-control"
          minLength={props.minLength}
          maxLength={props.maxLength}
        />
      </div>
    </div>
  );
}

CustomTextInput.propTypes = {
  allowedCharsRegex: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minLength: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  value: PropTypes.string,
  autoCapitalize: PropTypes.string,
  inputType: PropTypes.string.isRequired,
};

CustomTextInput.defaultProps = {};

export default CustomTextInput;
