import { IProject } from "../../interfaces/project";
import * as types from "../actions/actionTypes";
import initialState from "./theInitialState";

export default function projectsReducer(
  state: IProject[] = initialState.allProjects,
  action: {
    type: string;
    allProjects: IProject[];
    project: IProject;
  }
) {
  switch (action.type) {
    case types.TOKENS_UPDATED:
      return action.allProjects;
    case types.CREATE_PROJECT_COMPLETE:
      const aggregate = [...state.concat(action.project)];
      return aggregate;
    case types.UPDATE_PROJECT_COMPLETE:
      const arr: IProject[] = state.map((s) => {
        if (s.id === action.project.id) {
          return action.project;
        } else {
          return s;
        }
      });
      return arr;

    case types.LOGOUT:
      return [];
    default:
      return state;
  }
}
