import * as types from "../actions/actionTypes";
import initialState from "./theInitialState";
import {IConsignmentCore} from "../../interfaces/consignment";

export default function createdConsignmentsReducer(
  state: IConsignmentCore[] = initialState.createdConsignments,
  action: {
    type: string;
    consignmentsByTime: IConsignmentCore[];
    isCreation: boolean;
  }
) {
  switch (action.type) {
    case types.GET_BULK_CONSIGNMENTS_COMPLETE:
      return action.isCreation ? action.consignmentsByTime : state;
    case types.LOGOUT:
      return [];
    default:
      return state;
  }
}
