import { defaultProjectDetail, IProjectDetail } from "../../interfaces/project";
import * as types from "../actions/actionTypes";
import initialState from "./theInitialState";

export default function selectedProjectReducer(
  state: IProjectDetail = initialState.selectedProject,
  action: {
    type: string;
    projectDetail: IProjectDetail;
  }
) {
  switch (action.type) {
    case types.GET_PROJECT_DETAIL_COMPLETE:
      return action.projectDetail;

    case types.LOGOUT:
      return defaultProjectDetail;
    default:
      return state;
  }
}
