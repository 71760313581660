import * as projectActions from "../redux/actions/projectActions";
import { bindActionCreators } from "redux";
import { useState } from "react";
import { ITokenPair } from "../interfaces/auth";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import CustomTextInput from "../CustomTextInput";
import { connect } from "react-redux";
import {
  IProject,
  IProjectDetail,
  ICreateUpdateProject,
  defaultProject,
} from "../interfaces/project";
import { IDestination } from "../interfaces/destination";
import Dropdown from "react-dropdown";
function UpdateProject(props: {
  projectActions: {
    updateProject: (
      projectDetails: ICreateUpdateProject,
      oldProjectName: string,
      accessToken: string
    ) => void;
  };
  tokenPair: ITokenPair;
  allProjects: IProject[];
  allDestinations: IDestination[];
}) {
  const [destinations, setDestinations] = useState<IDestination[]>([]);
  const [projectDescription, setProjectDescription] = useState("");
  const [project, setProject] = useState<IProject>(
    props.allProjects && props.allProjects.length > 0
      ? props.allProjects[0]
      : defaultProject
  );
  async function onSelectProject(selectedProject: any): Promise<void> {
    setProject(
      props.allProjects.find((p) => p.name === selectedProject.label) ||
        defaultProject
    );
  }
  function onSaveProjectDescription(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setProjectDescription(value);
  }
  const [updateProjectName, setUpdateProjectName] = useState("");
  function onSaveNewProjectName(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setUpdateProjectName(value);
  }
  function onSubmitUpdateProject(event: {
    cancelable: any;
    preventDefault: () => void;
  }) {

    if (event.cancelable) event.preventDefault();

    if (updateProjectName.length === 0) {
      toast.error("project must have a name");
    } else if (projectDescription.length === 0) {
      toast.error("project must have a description");
    } else if (
      updateProjectName.toLowerCase() !== project.name.toLowerCase() &&
      props.allProjects
        .map((p) => p?.name?.toLowerCase())
        .includes(updateProjectName.toLowerCase())
    ) {
      toast.error("Project already exists with name " + updateProjectName+":"+project.name.toLowerCase());
    } else if (destinations.length === 0) {
      toast.error("Project must have at least one destination");
    } else {
      props.projectActions.updateProject(
        {
          description: projectDescription,
          id: project.id,
          name: updateProjectName,
          destinations: destinations.map((d) => d.id),
        },
        project.name,
        props?.tokenPair?.accessDetails?.accessToken
      );
    }
  }
  return (
    <form onSubmit={onSubmitUpdateProject} className="admin">
      <h4>Update Project Details</h4>
      <div>
        <label>Select Project To Update </label>
        <Dropdown
          options={props.allProjects.map((p) => p.name)}
          onChange={onSelectProject}
          value={props.allProjects[0]?.name || ""}
          placeholder="Select a project"
        />
      </div>
      <div className="admin-width">
        <CustomTextInput
          name="name"
          label="Name"
          id="name"
          onChange={onSaveNewProjectName}
          minLength={1}
          maxLength={35}
          allowedCharsRegex={""}
          autoCapitalize={"none"}
          inputType="text"
          error={[]}
        />
      </div>
      <div>
        <CustomTextInput
          name="name"
          label="Description"
          id="description"
          onChange={onSaveProjectDescription}
          minLength={1}
          maxLength={350}
          allowedCharsRegex={""}
          autoCapitalize={"none"}
          inputType="text"
          error={[]}
        />
      </div>
      <div>
        <Multiselect
          options={props.allDestinations} // Options to display in the dropdown
          selectedValues={destinations} // Preselected value to persist in dropdown
          onSelect={setDestinations} // Function will trigger on select event
          onRemove={setDestinations}
          displayValue="name" // Property name to display in the dropdown options
          placeholder="Destinations"
        />
      </div>
      <button type="submit" className="admin-button btn btn-primary">
        {"Update Project"}
      </button>
    </form>
  );
}
const mapStateToProps = (state: {
  tokenPair: ITokenPair;
  allProjects: IProject[];
  allDestinations: IDestination[];
  projectDetail: IProjectDetail;
}) => {
  return {
    tokenPair: state.tokenPair,
    allProjects: state.allProjects,
    allDestinations: state.allDestinations,
    projectDetail: state.projectDetail,
  };
};
const mapDispatchToProps = (dispatch: (arg0: { type: string }) => any) => {
  return {
    projectActions: bindActionCreators(projectActions, dispatch),
  };
};
UpdateProject.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProject);
