export const TOKENS_UPDATED = "TOKENS_UPDATED";
export const LOGOUT = "LOGOUT";
export const LOAD_CONSIGNMENTS = "LOAD_CONSIGNMENTS";
export const GET_BULK_CONSIGNMENTS_COMPLETE = "GET_BULK_CONSIGNMENTS_COMPLETE";
export const GET_DESTINATIONS_COMPLETE = "GET_DESTINATIONS_COMPLETE";
export const CREATE_DESTINATION_COMPLETE = "CREATE_DESTINATION_COMPLETE";
export const UPDATE_DESTINATION_COMPLETE = "UPDATE_DESTINATION_COMPLETE";
export const GET_PROJECT_DETAIL_COMPLETE = "GET_PROJECT_DETAIL_COMPLETE";
export const CREATE_PROJECT_COMPLETE = "CREATE_PROJECT_COMPLETE";
export const UPDATE_PROJECT_COMPLETE = "UPDATE_PROJECT_COMPLETE";
export const GET_DISPOSAL_TYPES_COMPLETE = "GET_DISPOSAL_TYPES_COMPLETE";
export const CREATE_DISPOSAL_TYPE_COMPLETE = "CREATE_DISPOSAL_TYPE_COMPLETE";
export const UPDATE_DISPOSAL_TYPE_COMPLETE = "UPDATE_DISPOSAL_TYPE_COMPLETE";

