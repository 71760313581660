import * as types from "./actionTypes";

import {
  IProjectDetail,
  defaultProjectDetail,
  ICreateUpdateProject,
} from "../../interfaces/project";
import { getProjectDetailFromServer, createProjectOnServer, updateProjectOnServer } from "../../api/projectApiRemote";



export function retrieveProjectDetail(projectId: string, accessToken: string) {
  return async function (dispatch: any) {
    try {
      const projectDetail: IProjectDetail = await getProjectDetailFromServer(
        projectId,
        accessToken
      );
      dispatch(retrieveProjectDetailComplete(projectDetail));
    } catch (error) {
      dispatch(retrieveProjectDetailComplete(defaultProjectDetail));
    }
  };

  function retrieveProjectDetailComplete(projectDetail: IProjectDetail): {
    type: string;
    projectDetail: IProjectDetail;
  } {
    return {
      type: types.GET_PROJECT_DETAIL_COMPLETE,
      projectDetail,
    };
  }
}

export function createProject(
  newProject: ICreateUpdateProject,
  accessToken: string
) {
  return async function (dispatch: any) {
    try {
      const project: IProjectDetail = await createProjectOnServer(
        newProject,
        accessToken
      );

      dispatch({ type: types.CREATE_PROJECT_COMPLETE, project });
    } catch (error) {
      dispatch({
        type: types.CREATE_PROJECT_COMPLETE,
        selectedProject: defaultProjectDetail,
      });
    }
  };
}
export function updateProject(
  updateProject: ICreateUpdateProject,
  oldProjectName: string,
  accessToken: string
) {
  return async function (dispatch: any) {
    try {
      const project: IProjectDetail = await updateProjectOnServer(
        updateProject,
        oldProjectName,
        accessToken
      );

      dispatch({ type: types.UPDATE_PROJECT_COMPLETE, project });
    } catch (error) {
      dispatch({
        type: types.UPDATE_PROJECT_COMPLETE,
        selectedProject: defaultProjectDetail,
      });
    }
  };
}