import * as types from "./actionTypes";

import {
  IDestination,
  defaultDestination,
  ICreateUpdateDestination
} from "../../interfaces/destination";
import {  createDestinationOnServer, updateDestinationtOnServer } from "../../api/destinationApiRemote";




export function createDestination(
  newDestination: ICreateUpdateDestination,
  accessToken: string
) {
  return async function (dispatch: any) {
    try {
      const destination: IDestination = await createDestinationOnServer(
        newDestination,
        accessToken
      );

      dispatch({ type: types.CREATE_DESTINATION_COMPLETE, destination});
    } catch (error) {
      dispatch({
        type: types.CREATE_DESTINATION_COMPLETE,
        selectedDestination: defaultDestination,
      });
    }
  };
}
export function updateDestination(
  updateDestination: ICreateUpdateDestination,
  oldDestinationName: string,
  accessToken: string
) {
  return async function (dispatch: any) {
    try {
      const destination: IDestination = await updateDestinationtOnServer(
        updateDestination,
        oldDestinationName,
        accessToken
      );

      dispatch({ type: types.UPDATE_DESTINATION_COMPLETE, destination });
    } catch (error) {
      dispatch({
        type: types.UPDATE_DESTINATION_COMPLETE,
        selectedDestination: defaultDestination,
      });
    }
  };
}