import { EMPTY_STRING } from "./PresentationConstants";

export const debugLevels = {
  ERROR: 0,
  INFO: 1,
  WARN: 2,
  DEBUG: 3,
  properties: [
    { name: "ERROR" },
    { name: "INFO" },
    { name: "WARN" },
    { name: "DEBUG" },
  ],
};
export const DEBUG_LEVEL = 2;
export const LOG_SEPARATOR = " : ";
export const LOG_ELIPSE = EMPTY_STRING;
export const INITIAL_IMAGE_NAME_IN_LOGS = "initial";
export const COMPLETED_IMAGE_NAME_IN_LOGS = "completed";

const API_REMOTE_LOG_PREFIX = ": API_REMOTE:";
const API_FAKE_LOG_PREFIX = ": API_FAKE:";
const REDUX_PREFIX = ": REDUX:";
const COMPONENTS_PREFIX = ": COMPONENTS:";
const IMAGE = "IMAGE";
const AUTH = "AUTH";
const CONSIGNMENT = "CONSIGMENT";
const DESTINATION = "DESTINATION";
export const contextLabels = {
  COMPONENT_IMAGE_DISPLAY: COMPONENTS_PREFIX + "IMAGE-DISPLAY",
  REDUX_CONSIGNMENT: REDUX_PREFIX + CONSIGNMENT,
  REDUX_IMAGE: REDUX_PREFIX + IMAGE,
  REDUX_DESTINATION: REDUX_PREFIX + DESTINATION,
  API_REMOTE_IMAGE: API_REMOTE_LOG_PREFIX + IMAGE,
  API_REMOTE_AUTH: API_REMOTE_LOG_PREFIX + AUTH,
  API_REMOTE_CONSIGNMENT: API_REMOTE_LOG_PREFIX + CONSIGNMENT,
  API_FAKE_IMAGE: API_FAKE_LOG_PREFIX + IMAGE,
  API_FAKE_AUTH: API_FAKE_LOG_PREFIX + AUTH,
  API_FAKE_CONSIGNMENT: API_FAKE_LOG_PREFIX + CONSIGNMENT,

};
