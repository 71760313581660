import * as disposalTypeActions from "../redux/actions/disposalTypeActions";
import { bindActionCreators } from "redux";
import { useState } from "react";
import { ITokenPair } from "../interfaces/auth";
import { toast } from "react-toastify";
import CustomTextInput from "../CustomTextInput";
import { connect } from "react-redux";
import { IDisposalType, defaultDisposalType } from "../interfaces/disposal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dropdown from "react-dropdown";
import { getEwcAsString } from "../common/presentationFunctions";

function UpdateDisposalType(props: {
  disposalTypeActions: {
    updateDisposalType: (
      ddisposalTypeDetails: IDisposalType,
      accessToken: string
    ) => void;
  };
  tokenPair: ITokenPair;
  allDisposalTypes: IDisposalType[];
}) {
  const [disposalTypeName, setDisposalTypeName] = useState<string>("");
  const [ewc, setEwc] = useState<string>("");
  const [isHazardous, setIsHazardous] = useState(false);
  const [disposalType, setDisposalType] = useState<IDisposalType>(
    props.allDisposalTypes.length > 0
      ? props.allDisposalTypes[0]
      : defaultDisposalType
  );
  async function onSelectDisposalType(
    selectedDisposalType: any
  ): Promise<void> {
    setDisposalType(
      props.allDisposalTypes.find(
        (d: IDisposalType) => selectedDisposalType.label.startsWith(getEwcAsString(d.ewc))
      ) || defaultDisposalType
    );
  }

  function onSaveDisposalTypeName(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setDisposalTypeName(value);
  }
  function onSaveEwc(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setEwc(value);
  }
  function handleSetIsHazardous(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    setIsHazardous(!isHazardous); 
  }

  function onSubmitUpdatedDisposalType(event: {
    cancelable: any;
    preventDefault: () => void;
  }) {
    if (event.cancelable) {
      event.preventDefault();
    }
    if (props.allDisposalTypes.length === 0) {
      return;
    }
    if (disposalTypeName.length === 0) {
      toast.error("disposal type must have a name");
    } else if (ewc.length !== 6) {
      toast.error("EWC code must be 6 digits");
    } else if (isNaN(parseInt(ewc))) {
      toast.error("EWC code must be 6 digits");
    } else if (
      ewc !== getEwcAsString(disposalType.ewc) &&
      props.allDisposalTypes.map((d) => getEwcAsString(disposalType.ewc)).includes(ewc)
    ) {
      toast.error("Disposal type with this EWC code already exists");
    } else if (getEwcAsString(disposalType.ewc) !== ewc) {
      toast.error(getEwcAsString(disposalType.ewc)  +"EWC code must not be changed to " + ewc);

    }
    else {
      props.disposalTypeActions.updateDisposalType(
        {
          id: disposalType.id,
          name: disposalTypeName,
          ewc: parseInt(ewc),
          hazardous: isHazardous,
        },
        props?.tokenPair?.accessDetails?.accessToken
      );
    }
  }
  return (
    <form onSubmit={onSubmitUpdatedDisposalType} className="admin">
      <h4>Disposal Type Update Details</h4>
      <div>
        <label>Select Disposal Type To Update </label>
        <Dropdown
          options={props.allDisposalTypes.map((d) => getEwcAsString(d.ewc) + " " + d.name)}
          onChange={onSelectDisposalType}
          value={props.allDisposalTypes[0]?.name || ""}
          placeholder="Select a disposal type"
        />
      </div>

      <div>
        <CustomTextInput
          name="name"
          label="Name"
          id="name"
          onChange={onSaveDisposalTypeName}
          minLength={1}
          maxLength={200}
          allowedCharsRegex={""}
          autoCapitalize={"none"}
          inputType="text"
          error={[]}
        />
      </div>
      <div>
        <CustomTextInput
          name="ewc"
          label="EWC"
          id="ewc"
          onChange={onSaveEwc}
          minLength={6}
          maxLength={6}
          allowedCharsRegex={"^[0-9]{0,6}$"}
          autoCapitalize={"none"}
          inputType="text"
          error={[]}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox checked={isHazardous} onChange={handleSetIsHazardous} />
          }
          label="Is Hazardous"
        />
      </div>

      <button type="submit" className="admin-button btn btn-primary">
        {"Update Disposal Type"}
      </button>
    </form>
  );
}
const mapStateToProps = (state: {
  tokenPair: ITokenPair;
  allDisposalTypes: IDisposalType[];
}) => {
  return {
    tokenPair: state.tokenPair,
    allDisposalTypes: state.allDisposalTypes,
  };
};
const mapDispatchToProps = (dispatch: (arg0: { type: string }) => any) => {
  return {
    disposalTypeActions: bindActionCreators(disposalTypeActions, dispatch),
  };
};
UpdateDisposalType.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDisposalType);
