import * as destinationActions from "../redux/actions/destinationActions";
import { bindActionCreators } from "redux";
import { useState } from "react";
import { ITokenPair } from "../interfaces/auth";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import CustomTextInput from "../CustomTextInput";
import { connect } from "react-redux";

import { IDestination, ICreateUpdateDestination, defaultDestination } from "../interfaces/destination";
import Dropdown from "react-dropdown";
import { IDisposalType } from "../interfaces/disposal";
import { augmentDisposalType } from "../common/presentationFunctions";

function UpdateDestination(props: {
  destinationActions: {
    updateDestination: (
      destination: ICreateUpdateDestination,
      oldDestinationName: string,
      accessToken: string
    ) => void;
  };
  tokenPair: ITokenPair;
  allDestinations: IDestination[];
  allDisposalTypes: IDisposalType[];
}) {
  const [disposalTypes, setDisposalTypes] = useState<IDisposalType[]>([]);
  const [operator, setOperator] = useState("");
  const [destinationName, setDestinationName] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [destination, setDestination] = useState<IDestination>(
    props.allDestinations && props.allDestinations.length > 0
      ? props.allDestinations[0]
      : defaultDestination
  );
  async function onSelectDestination(selectedDestination: any): Promise<void> {
    setDestination(
      props.allDestinations.find((d) => d.name === selectedDestination.label) ||
        defaultDestination
    );
  }
  function onSaveOperator(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setOperator(value);
  }
  function onSaveAddress(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setDestinationAddress(value);
  }
  function onSaveName(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setDestinationName(value);
  }
  function onSubmitNewDestination(event: {
    cancelable: any;
    preventDefault: () => void;
  }) {
    if (event.cancelable) {
      event.preventDefault() 
    }
    if (props.allDestinations.length === 0) {
      return;
    }

    if (destinationName.length === 0) {
      toast.error("destination must have a name");
    } else if (destinationAddress.length === 0) {
      toast.error("destination must have an address");
    } else if (
      destination.name.toLowerCase() !== destinationName.toLowerCase() &&
      props.allDestinations
        .map((p) => p?.name?.toLowerCase())
        .includes(destinationName.toLowerCase())
    ) {
      toast.error("Destination already exists with name " + destinationName);
    } else if (disposalTypes.length === 0) {
      toast.error("Destination must have at least one disposalType");
    } else {
      props.destinationActions.updateDestination(
        {
          operator: operator,
          id: destination.id,
          name: destinationName,
          address: destinationAddress,
          disposalTypes: disposalTypes.map((d) => d.id),
        },
       destination.name,
        props?.tokenPair?.accessDetails?.accessToken
      );
    }
  }

  return (

    <form onSubmit={onSubmitNewDestination} className="admin">
      <h4>Destination Update Details</h4>
      <div>
        <label>Select Destination To Update </label>
        <Dropdown
          options={props.allDestinations.map((d) => d.name)}
          onChange={onSelectDestination}
          value={props.allDestinations[0]?.name || ""}
          placeholder="Select a project"
        />
      </div>
      <div >
        <CustomTextInput
          name="destinationnewname"
          label="Name"
          id="name"
          onChange={onSaveName}
          minLength={1}
          maxLength={35}
          allowedCharsRegex={""}
          autoCapitalize={"none"}
          inputType="text"
          error={[]}
        />
      </div>
      <div>
        <CustomTextInput
          name="destinationaddress"
          label="Address"
          id="address"
          onChange={onSaveAddress}
          minLength={1}
          maxLength={350}
          allowedCharsRegex={""}
          autoCapitalize={"none"}
          inputType="text"
          error={[]}
        />
      </div>
      <div>
        <CustomTextInput
          name="operator"
          label="Operator"
          id="perator"
          onChange={onSaveOperator}
          minLength={1}
          maxLength={350}
          allowedCharsRegex={""}
          autoCapitalize={"none"}
          inputType="text"
          error={[]}
        />
      </div>
      <div>
        <Multiselect
          options={props.allDisposalTypes.map(o => (augmentDisposalType(o)))} // Options to display in the dropdown
          selectedValues={disposalTypes} // Preselected value to persist in dropdown
          onSelect={setDisposalTypes} // Function will trigger on select event
          onRemove={setDisposalTypes}
          displayValue="displayValue"  // Property name to display in the dropdown options
          placeholder="Disposal Types"
        />
      </div>
      <button type="submit" className="admin-button btn btn-primary">
        {"Update Destination"}
      </button>
    </form>
  );

}
const mapStateToProps = (state: {
  tokenPair: ITokenPair;
  allDestinations: IDestination[];
  allDisposalTypes: IDisposalType[];
}) => {
  return {
    tokenPair: state.tokenPair,
    allDestinations: state.allDestinations,
    allDisposalTypes: state.allDisposalTypes
  };
};
const mapDispatchToProps = (dispatch: (arg0: { type: string }) => any) => {
  return {
    destinationActions: bindActionCreators(destinationActions, dispatch),
  };
};
UpdateDestination.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDestination);
