import * as disposalTypeActions from "../redux/actions/disposalTypeActions";
import { bindActionCreators } from "redux";
import { useState } from "react";
import { ITokenPair } from "../interfaces/auth";
import { toast } from "react-toastify";
import CustomTextInput from "../CustomTextInput";
import { connect } from "react-redux";
import { IDisposalType } from "../interfaces/disposal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function CreateDisposalType(props: {
  disposalTypeActions: {
    createDisposalType: (
      ddisposalTypeDetails: IDisposalType,
      accessToken: string
    ) => void;
  };
  tokenPair: ITokenPair;
  allDisposalTypes: IDisposalType[];
}) {
  const [name, setName] = useState<string>("");
  const [ewc, setEwc] = useState<string>("");
  const [isHazardous, setIsHazardous] = useState(false);
  function onSaveName(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setName(value);
  }
  function onSaveEwc(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    event.preventDefault();
    const { value } = event.target;
    setEwc(value);
  }
  function handleSetIsHazardous(event: {
    preventDefault: () => void;
    target: { name: any; value: any };
  }) {
    setIsHazardous(!isHazardous); //look at the !NOT sign
  };

  function onSubmitNewDisposalType(event: {
    cancelable: any;
    preventDefault: () => void;
  }) {
    if (event.cancelable) event.preventDefault();

    if (name.length === 0) {
      toast.error("disposal type must have a name");
    } else if (ewc.length !== 6) {
      toast.error("EWC code must be 6 digits");
    } else if (isNaN(parseInt(ewc))) {
      toast.error("EWC code must be 6 digits");
    } else if (
      props.allDisposalTypes.map((d) => d.ewc).includes(parseInt(ewc))
    ) {
      toast.error("Disposal type with this EWC code already exists");
    } else {
      props.disposalTypeActions.createDisposalType(
        {
          id: "",
          name,
          ewc: parseInt(ewc),
          hazardous: isHazardous,
        },
        props?.tokenPair?.accessDetails?.accessToken
      );
    }
  }
  return (
    <form onSubmit={onSubmitNewDisposalType} className="admin">
      <h4>New Disposal Type Details</h4>
      <div>
        <CustomTextInput
          name="name"
          label="Name"
          id="name"
          onChange={onSaveName}
          minLength={1}
          maxLength={200}
          allowedCharsRegex={""}
          autoCapitalize={"none"}
          inputType="text"
          error={[]}
        />
      </div>
      <div>
        <CustomTextInput
          name="ewc"
          label="EWC"
          id="ewc"
          onChange={onSaveEwc}
          minLength={6}
          maxLength={6}
          allowedCharsRegex={"^[0-9]{0,6}$"}
          autoCapitalize={"none"}
          inputType="text"
          error={[]}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox checked={isHazardous} onChange={handleSetIsHazardous} />
          }
          label="Is Hazardous"
        />
      </div>

      <button type="submit" className="admin-button btn btn-primary">
        {"Create Disposal Type"}
      </button>
    </form>
  );
}
const mapStateToProps = (state: {
  tokenPair: ITokenPair;
  allDisposalTypes: IDisposalType[];
}) => {
  return {
    tokenPair: state.tokenPair,
    allDisposalTypes: state.allDisposalTypes,
  };
};
const mapDispatchToProps = (dispatch: (arg0: { type: string }) => any) => {
  return {
    disposalTypeActions: bindActionCreators(disposalTypeActions, dispatch),
  };
};
CreateDisposalType.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDisposalType);
