import * as types from "../actions/actionTypes";
import { ITokenPair } from "../../interfaces/auth";
import initialState from "./theInitialState";

export default function tokenReducer(
  state: ITokenPair = initialState.tokenPair,
  action: {
    type: string;
    tokenPair: ITokenPair;
  }
) {
  switch (action.type) {
    case types.TOKENS_UPDATED:
      return action.tokenPair;
    case types.LOGOUT:
      return initialState.tokenPair
    default:
      return state;
  }
}
